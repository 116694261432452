import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { enviorment } from '@core/settings/enviorment';
import { UsuarioService } from './usuario.service';
import { Campania1 } from '@core/models/Campania1';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  var1 = JSON.parse(localStorage.getItem('token'));
  tokenHeader = new HttpHeaders({'Authorization': 'Bearer ' + this.var1});

  constructor(public http: HttpClient,
              public _usuarioService: UsuarioService) {

              }

  postCampaign(campaign: Campania1): Observable<any> {
    campaign.userId = this._usuarioService.getUsuario().id;
    campaign.CompanyId = this._usuarioService.getUsuario().companyId;
    const token = this.getToken();

    return this.http.post(enviorment + 'Campaign', campaign, { headers: token} );
  }

  getDays(): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/Days', { headers: token});
  }

  getCampaigns(): Observable<any> {
    const token = this.getToken();
    const idCompany = this._usuarioService.getUsuario().companyId;
    return this.http.get(enviorment + 'Campaign/GetCampaignGrid/' + idCompany, { headers: token});
  }

  deleteCampaign(id): Observable<any> {
    const token = this.getToken();
    return this.http.delete(enviorment + 'Campaign/' + id, { headers: token});
  }

  getCampaignById(id): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/' + id, { headers: token});
  }

  activeCampaign(id): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/ActiveCampaign/' + id, { headers: token});
  }

  pausarCampaign(id): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/PauseCampaign/' + id, { headers: token});
  }

  getSPMaxColumn(trace, segment): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/GetSPMaxColumn/' + trace + '/' + segment, { headers: token});
  }

  campaignPrevisualizar(campaignPrev: Campania1): Observable<any> {
    const token = this.getToken();
    campaignPrev.CompanyId = this._usuarioService.getUsuario().companyId;
    return this.http.post(enviorment + 'Campaign/CampaignPrevisualizar', campaignPrev, { headers: token});
  }

  getReport(idCampaign): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/ReportCampaign/' + idCampaign, { headers: token});
  }

  public requestDataFromMultipleSources(): Observable<any[]> {
    const token = this.getToken();
    const idCompany = this._usuarioService.getUsuario().companyId;
    const response1 = this.http.get(enviorment + 'Campaign/GetCampaignsActivesByIdComp/' + idCompany, { headers: token});
    const response2 = this.http.get(enviorment + 'Campaign/GetCampaignsStatesByHome/' + idCompany, { headers: token});
    // const response3 = this.http.get(enviorment + 'Campaign/CantMsjEnviadosMes/' + idCompany, { headers: token});
    return forkJoin([response1, response2]);
  }

  getCantMsjEnviadosMes(): Observable<any> {
    const token = this.getToken();
    const idCompany = this._usuarioService.getUsuario().companyId;
    return this.http.get(enviorment + 'Campaign/CantMsjEnviadosMes/' + idCompany, { headers: token});
  }

  getGetCantidadMensajes(): Observable<any> {
    const idCompany = this._usuarioService.getUsuario().companyId;
    return this.http.get(enviorment + 'Campaign/GetCantidadMensajes/' + idCompany);
  }

  getDateString(date: Date) {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  // Este método obtiene la cantidad (Admin de la compania)
  getGetCantidadMensajesFilterDate(dateStart: Date = null, dateEnd: Date = null): Observable<any> {
    const params: any = {
      idCompany: this._usuarioService.getUsuario().companyId, // 1,
      dateStart: dateStart ? this.getDateString(dateStart) : null, // '10/1/2019'
      dateEnd: dateEnd ? this.getDateString(dateEnd) : null // '10/31/2019'
    };
    return this.http.get(enviorment + 'Campaign/CantMsjEnviadosFilterDate', {params: params});
  }

  // Este método obtiene la cantidad (SuperAdmin de la compania)
  getGetCantidadMensajesFilterDateAdmin(dateStart: Date = null, dateEnd: Date = null, idComp): Observable<any> {
    console.log(idComp);
    const params: any = {
      idCompany: idComp, // 1,
      dateStart: dateStart ? this.getDateString(dateStart) : null, // '10/1/2019'
      dateEnd: dateEnd ? this.getDateString(dateEnd) : null // '10/31/2019'
    };
    return this.http.get(enviorment + 'Campaign/CantMsjEnviadosFilterDate', {params: params});
  }

  getBandejaEntrada(): Observable<any> {
    const idCompany = this._usuarioService.getUsuario().companyId;
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/BandejaEntrada/' + idCompany, { headers: token});
  }

  getBandejaEntradaAdmin(): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/BandejaEntradaAdmin', { headers: token});
  }

  getReportBandejaEntrada(idCampaign): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/Reporte_BandejaEntrada/' + idCampaign, { headers: token});
  }

  getReportGalicia(idCampaign): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/Reporte_Galicia/' + idCampaign, { headers: token});
  }

  getCampaignsActivesAllClient(): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/GetCampaignsActives', { headers: token});
  }

  reporteGalicia(): Observable<any> {
    return this.http.get('https://jsonplaceholder.typicode.com/todos/1',{ responseType: 'blob'}, );
  }

  getCampaignsAllAdmin(): Observable<any> {
    const token = this.getToken();
    return this.http.get(enviorment + 'Campaign/GetAllCampaignAdmin/', { headers: token});
  }

  updateCampaign(camp: Campania1): Observable<any> {
    const token = this.getToken();
    return this.http.put(enviorment + 'Campaign/' + camp.id, camp , { headers: token});
  }

  reporteAdminPorMes(dateMonth: Date): Observable<any> {
    console.log(dateMonth);
    const params: any = {
      dateMonth: dateMonth ? this.getDateString(dateMonth) : null,
    };
    return this.http.get(enviorment + 'Campaign/ReporteAdminPorMes', {params: params});
  }

  reporteGaliciaPorMes(dateMonth: Date): Observable<any> {
    console.log(dateMonth);
    const params: any = {
      dateMonth: dateMonth ? this.getDateString(dateMonth) : null,
    };
    return this.http.get(enviorment + 'Campaign/ReporteGaliciaPorMes', {params: params});
  }

  getToken() {
    const var12 = JSON.parse(localStorage.getItem('token'));
    const tokenHeader1 = new HttpHeaders({'Authorization': 'Bearer ' + var12});
    return tokenHeader1;
  }
}
